
  import NLink from '~/components/utils/NLink'
  export default {
    name: 'UpperNavbarItem',
    components: { NLink },
    props: {
      type: {
        type: String
      },
      item: {
        type: Object,
        required: true
      },
      disabled: Boolean
    },
    data () {
      return {
        isDropdownActive: false,
        active: {
          value: '',
          label: ''
        }
      }
    },
    computed: {
      currentLabel(vm) {
        const item = vm.item

        return item.options[item.current]
      }
    }
  }
